<!--No longer in use?-->

<template>
    <div>
        <card-header title="Itemized Registration" icon="fa-file-invoice"/>

        <card-body padded class="content">

            <div class="columns">
                <div class="column is-8">
                    <h5 class="mb-1"><i class="far fa-tennis-ball mr-3"></i>{{ trip.title }}</h5>
                    <div class="ml-5 pl-2 pb-5 is-size-7">
                        <div>{{ trip.start_on | moment('MMMM, Do, YYYY') }} - {{ trip.end_on | moment('MMMM, Do, YYYY') }}</div>
                    </div>
                </div>
                <div class="column has-text-right is-size-7">
                    <div>
                        <i class="far fa-user mr-2"></i>{{ registration.user.first_name }} {{ registration.user.last_name }}
                        <span v-if="registration.second_id" class="ml-3">
                            <i class="far fa-user-friends mr-2"></i>{{ registration.second_user.first_name }} {{ registration.second_user.last_name }}
                        </span>
                    </div>
                    <div class="mt-2">
                        <strong>Total: </strong>{{plan.total | currency}}
                    </div>
                    <div class="">
                        <strong>Paid: </strong>{{plan.paid | currency}}
                    </div>
                    <div class="">
                        <strong>Remaining: </strong>{{(plan.total - plan.paid) | currency}}
                    </div>
                </div>
            </div>

            <table class="table is-bordered" style="width: 100%; font-size: 0.8rem;">
                <tr>
                    <th style="border: none;"></th>
                    <th class="has-text-right">PP/Night</th>
                    <th class="has-text-right">#</th>
                    <th class="has-text-right">Total</th>
                </tr>
                <tr>
                    <td colspan="4" style="font-size: 0.65rem" class="pb-0 pt-1 has-background-grey-lighter has-text-weight-bold is-uppercase">Trip</td>
                </tr>
                <tr>
                    <td class="has-text-weight-bold">{{ trip.title }}</td>
                    <td class="has-text-right">{{ trip.cost | currency }}</td>
                    <td class="has-text-right">{{ (registration.second_id) ? 2 : 1 }}</td>
                    <td class="has-text-right">{{ trip.cost * ((registration.second_id) ? 2 : 1) | currency }}</td>
                </tr>
                <tr>
                    <td colspan="4" style="font-size: 0.65rem" class="pb-0 pt-1 has-background-grey-lighter has-text-weight-bold is-uppercase">Add-Ons</td>
                </tr>
                <tr v-for="a in registration.addons">
                    <td class="has-text-weight-bold">{{ a.title }}<i class="far fa-cogs ml-2" v-if="a.override"></i></td>
                    <td class="has-text-right">{{ a.pp | currency }}</td>
                    <td class="has-text-right">{{ a.quantity }}</td>
                    <td class="has-text-right">{{ a.total | currency }}</td>
                </tr>
                <tr>
                    <td style="border: none;"></td>
                    <td colspan="2" class="has-text-weight-bold has-text-right">Total: </td>
                    <td class="has-text-weight-bold has-text-right" style="white-space: nowrap"><span v-if="total !== plan.total"><i class="fas fa-exclamation-triangle has-text-warning mr-3"></i></span>{{ total | currency }}</td>
                </tr>
            </table>

            <h6 class="mt-4">Payment Plan</h6>

            <table class="table is-bordered" style="width: 100%; font-size: 0.8rem;">
                <tr>
                    <th style="border: none;"></th>
                    <th>Date</th>
                    <th>Paid</th>
                    <th class="has-text-right">PP/Night</th>
                    <th class="has-text-right">#</th>
                    <th class="has-text-right">Total</th>
                </tr>
                <tr v-for="i in plan.items">
                    <td class="has-text-weight-bold">{{i.title}}</td>
                    <td>{{i.due_on | moment('MMM D, YYYY')}}</td>
                    <td><i class="fas fa-check" v-if="i.paid===1"></i><i class="fas fa-ban" v-else></i></td>
                    <td class="has-text-right">{{i.amount | currency}}</td>
                    <td class="has-text-right">{{i.quantity}}</td>
                    <td class="has-text-right">{{i.total | currency}}</td>
                </tr>
                <tr>
                    <td style="border: none;"></td>
                    <td colspan="4" class="has-text-weight-bold has-text-right">Total: </td>
                    <td class="has-text-weight-bold has-text-right"><span v-if="Math.abs(plan.total - itemsTotal) > 0.10"><i class="fas fa-exclamation-triangle has-text-warning mr-3"></i></span>{{ itemsTotal | currency }}</td>
                </tr>
                <tr>
                    <td style="border: none;"></td>
                    <td colspan="4" class="has-text-weight-bold has-text-right">Paid: </td>
                    <td class="has-text-weight-bold has-text-right"><span v-if="Math.abs(plan.paid - itemsPaid) > 0.10"><i class="fas fa-exclamation-triangle has-text-warning mr-3"></i></span>{{ itemsPaid | currency }}</td>
                </tr>
                <tr>
                    <td style="border: none;"></td>
                    <td colspan="4" class="has-text-weight-bold has-text-right">Remaining: </td>
                    <td class="has-text-weight-bold has-text-right"><span v-if="Math.abs((plan.total - plan.paid) - itemsRemaining) > 0.10"><i class="fas fa-exclamation-triangle has-text-warning mr-3"></i></span>{{ itemsRemaining | currency }}</td>
                </tr>
            </table>
            <div class="mb-6"></div>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DataRow from "../../TIER/components/DataRow";
    import CustomerListItem from "../../components/CustomerListItem";

    import async from "async";

    export default {
        name: "Registration",
        props: ['card', 'child', 'props'],
        components: {CustomerListItem, DataRow, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                trip: {},
                registration: {
                    trip: {},
                    user: {},
                    addons: []
                },
                plan: {
                    paid: 0,
                    total: 0
                }
            };
        },
        computed: {
            total: function() {
                let total = (this.registration.second_id) ? (this.trip.cost * 2) : this.trip.cost;
                for (let i = 0; i < this.registration.addons.length; i++) {
                    const addon = this.registration.addons[i];
                    total += addon.total;
                }
                return total;
            },
            itemsTotal: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    total += item.total;
                }
                return total;
            },
            itemsPaid: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.paid === 1) total += item.total;
                }
                return total;
            },
            itemsRemaining: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.paid === 0) total += item.total;
                }
                return total;
            }
        },
        methods: {
            loadRegistration: function() {
                this.$emit('loading', true);

                async.series([
                    (cb) => {
                        http.get('/api/registrations/' + this.props.regId).then(response => {
                            this.registration = response.data;

                            for (let i = 0; i < this.registration.addons.length; i++) {
                                const addon = this.registration.addons[i];
                                addon.title = (addon.override_title) ? addon.override_title : addon.title;

                                if (addon.override_price !== null || addon.override_price_double !== null) {
                                    addon.override = true;

                                    if (this.registration.second_id) {
                                        addon.pp = addon.override_price_double / 2;
                                        addon.quantity = 2;
                                        addon.total = addon.override_price_double;
                                    } else {
                                        addon.pp = addon.override_price;
                                        addon.quantity = 1;
                                        addon.total = addon.override_price;
                                    }
                                } else {
                                    if (this.registration.second_id) {
                                        addon.pp = addon.double_cost / 2;
                                        addon.quantity = 2;
                                        addon.total = addon.double_cost;
                                    } else {
                                        addon.pp = addon.single_cost;
                                        addon.quantity = 1;
                                        addon.total = addon.single_cost;
                                    }
                                }
                            }

                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/trips/' + this.registration.trip_id).then(response => {
                            this.trip = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/payment_plans/' + this.registration.payment_plan.id).then(response => {
                            this.plan = response.data;
                            this.plan.remaining = response.total - response.paid;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);

                })


            }
        },
        watch: {
            'props': function() {
                this.loadRegistration();
            }
        },
        mounted() {
            this.loadRegistration(true);
        }
    };
</script>

<style lang="scss" scoped>

</style>
